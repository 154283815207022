import React from "react";
import Navbar from "./Navbar";
import styles from "../style";
import { servicesData, ServiceBox } from "../constants";
import { services, background1 } from "../assets";
const Services = () => {
  return (
    <>
      <div
        className={`${styles.flexCenter} relative mt-5 pt-10  sm:py-13 py-4  `}
      >
        <img
          src={background1}
          alt="Image"
          className={`w-full h-auto object-cover sm:py-9 py-2`}
        />
        <div className="absolute inset-0 flex flex-col  justify-center text-white">
          <h2
            className={`text-[40px] pl-10 font-poppins leading-[24px] pt-30 mt-20   font-bold mb-4 ml-6 text-teal-600`}
          >
            Our Services
          </h2>
          <p className="text-[18px] pl-10  ml-6 sm:block hidden">
            A leading provider of quality and regulatory affairs services
          </p>
        </div>
      </div>
      {/* line added */}
      <div className="mt-6 text-center">
        <h2 className="text-2xl font-poopins sm:text-4xl font-bold text-teal-600">
          MEBIOCON at a glance
        </h2>
        <p className="text-base font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
          Our commitment to excellence is rooted in our unwavering dedication to
          our clients' success.
        </p>
      </div>

      <div className="flex flex-col mt-10 pt-10 md:flex-row items-center justify-center pb-4">
        <div className="md:w-1/2">
          <h1 className="font-poppins pl-4 text-4xl font-bold mb-4 text-teal-600 ml-4">
            SERVICES
          </h1>
          <p className="font-poppins p-5 text-[10px] sm:text-[16px] text-justify text-black mb-6 m-4">
            In our comprehensive range of services, we offer a comprehensive
            suite of services that includes both preparing technical
            documentation and providing objective analysis and evaluation,
            enabling you to expedite the launch of your medical devices to the
            market. <br /> <br /> We specialize in quality management systems,
            technical documentation, audits, and inspections. With a deep
            understanding of quality principles and industry best practices, we
            assist organizations in implementing robust quality management
            systems tailored to their specific needs. Our dedicated team excels
            in creating and maintaining precise technical documentation,
            ensuring that vital information is effectively communicated to
            users, stakeholders and regulatory bodies. <br /> <br /> Through our
            rigorous audit and inspection processes, we meticulously evaluate
            compliance with quality standards, identify areas for improvement,
            and provide actionable recommendations. With a commitment to
            excellence, we help our clients enhance their operational
            efficiency, minimize risks, and achieve the highest levels of
            quality in their products or services.
          </p>
        </div>
        <div className="md:w-1/2 w-full">
          <img
            src={services}
            alt="Image"
            className="md:max-w-full mt-4 shadow-2xl rounded-lg "
          />
        </div>
      </div>
      {/* line added */}
      <div className="mt-10 mb-10 pt-10  text-center">
        <h2 className="text-2xl mt-10  font-poopins sm:text-4xl font-bold text-teal-600">
          Your trusted Partner
        </h2>
        <p className="text-base font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
          At Mebiocon, we recognize that navigating the complex world of medical
          device regulations can be challenging. That's why our team of
          experienced consultants is here to guide you through the regulatory
          process, ensuring your products meet the highest standards of safety
          and quality.
        </p>
      </div>
      <div className="mt-10 pt-10 font-poopins text-center">
        <h2 className="text-2xl  pt-10 font-poopins sm:text-4xl font-bold text-teal-600">
          What We Serve
        </h2>
      </div>
      <div className="services-container mb-8 m-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {servicesData.map((service, index) => (
          <ServiceBox
            key={index}
            title={service.title}
            content={service.content}
            image={service.image}
          />
        ))}
      </div>
    </>
  );
};

export default Services;
