import React from "react";
import styles from "../style";
import {
  background1,
  background2,
  background3,
  bg4,
  documentation1,
  manworking1,
  manworking4,
} from "../assets";
const About = () => {
  return (
    <>
      <div
        className={`${styles.flexCenter} relative mt-5 pt-10  sm:py-13 py-4  `}
      >
        <img
          src={background1}
          alt="Image"
          className={`w-full h-auto object-cover sm:py-9 py-2`}
        />
        <div className="absolute inset-0 flex flex-col  justify-center text-white">
          <h2
            className={`text-[40px] pl-10 font-poppins leading-[24px] pt-30 mt-20 font-bold mb-4 ml-6 text-teal-600`}
          >
            About Us
          </h2>
          <p className="text-[18px] pl-10  ml-6 sm:block hidden">
            A leading provider of quality and regulatory affairs services
          </p>
        </div>
      </div>

      {/* line added */}
      <div className="mt-6 text-center pb-9">
        <h2 className="text-2xl font-poopins sm:text-4xl font-bold text-teal-600">
          Why Us?
        </h2>
        <p className="text-base  text-black font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
          We are here to be your trusted regulatory partner, providing guidance,
          support, and peace of mind as you navigate the complexities of medical
          device regulations.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center justify-center pb-4">
        <div className="md:w-1/2">
          <h1 className="font-poppins pl-4 text-4xl font-bold mb-4 text-teal-600 ml-4">
            About Us
          </h1>
          <p className="font-poppins p-5 text-[10px] sm:text-[16px] text-black text-justify mb-6 m-4">
            With a deep understanding of the ever-evolving regulatory landscape,
            we are dedicated to assisting medical device manufacturers,
            distributors, and innovators in achieving compliance and bringing
            their products to market efficiently and successfully. At MEBIOCON,
            we recognize that navigating the complex world of medical device
            regulations can be challenging. That's why our team of experienced
            consultants is here to guide you through the regulatory process,
            ensuring your products meet the highest standards of safety and
            quality. We combine our expertise in regulatory affairs with a
            passion for innovation, helping you bring life-changing medical
            devices to patients worldwide. From initial product classification
            and regulatory pathway selection to document preparation,
            submission, and post-market surveillance, we provide comprehensive
            support throughout the entire product lifecycle.
          </p>
        </div>
        <div className="md:w-1/2 w-full">
          <img
            src={bg4}
            alt="Image"
            className="md:max-w-full mt-4 rounded-lg shadow-2xl"
          />
        </div>
      </div>
      {/* //why */}

      <h1 className="text-center p-4 font-poppins text-4xl font-bold mt-4 pt-5 text-teal-600 ml-4">
        Why choose MEBIOCON?
      </h1>

      <div className="flex flex-col mb-4 md:flex-row  items-center justify-center ">
        <div className="md:w-1/2 w-full">
          <img
            src={documentation1}
            alt="Image"
            className="md:max-w-full mt-4 rounded-lg shadow-2xl "
          />
        </div>
        <div className="md:w-1/2">
          <p className="font-poppins p-5 text-[10px] sm:text-[16px] text-justify text-black mb-4 m-4 pt-4">
            Our team brings together a wealth of regulatory knowledge, technical
            expertise, and industry insights. We stay abreast of the latest
            regulations and industry trends to provide proactive and timely
            advice, ensuring your compliance with global regulations such as FDA
            (U.S. Food and Drug Administration), CE marking (European
            Conformity), and other international standards. Our goal is not only
            to facilitate regulatory approvals but also to help you optimize
            your product development processes, minimize risk, and accelerate
            time to market. Partner with Mebiocon, and experience the difference
            of working with a dedicated team committed to your success. We are
            here to be your trusted regulatory partner, providing guidance,
            support, and peace of mind as you navigate the complexities of
            medical device regulations. Together, let's revolutionize healthcare
            and improve patient outcomes through compliant and innovative
            medical devices.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
