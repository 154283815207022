import React, { useState, useEffect } from "react";

import styles from "../style";
import { Link, useLocation } from "react-router-dom";

const Slider = ({ images, texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  return (
    <div className=" pt-10 flex flex-col">
      <div className="flex items-center justify-center h-64 relative">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Slider"
            className={`w-screen bg-image background-cover object-cover brightness-25 absolute transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-10000" : "opacity-0"
            }`}
          />
        ))}
        <div className="absolute  font-poppins left-4 sm:left-20  top-1/4  sm:top-3/4">
          <h2 className="text-2xl text-white  sm:text-6xl">
            Your trusted <br />{" "}
            <span className="text-teal-400">Quality & Regulatory</span> <br />{" "}
            companion
          </h2>
          <p className="text-white text-1xl sm:text-2xl  pt-5">
            A leading provider of quality and regulatory affairs services
          </p>

          <Link to="/Contact">
            <button
              type="button"
              className={`py-4 px-6 mt-10 font-poppins font-medium text-[18px] text-primary hover:bg-teal-500 bg-teal-400 rounded-[10px] outline-none ${styles}`}
            >
              Get In Touch
            </button>
          </Link>
        </div>
      </div>

      {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2">
        <div className="flex space-x-2">
          {images.map((_, index) => (
            <button
              key={index}
              className={`h-3 w-3 rounded-full ${
                index === currentIndex ? "bg-black" : "bg-gray-300"
              }`}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Slider;
