import { features } from "../constants";
import styles, { layout } from "../style";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";

const Business = () => (
  <>
    <div className={`bg-white sm:px-16 px-6 ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section id="features" className={layout.section}>
          <div className={layout.sectionInfo}>
            <h2 className={styles.heading2}>
              We do the paperwork,so <br className="sm:block hidden" /> you
              handle the business
            </h2>
            <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
              We are committed to delivering quality services to our clients and
              actively encourage feedback. Our company values professionalism
              and supports individual growth.
            </p>

            <Link to="/Contact">
              <button
                type="button"
                className={`py-4 px-6 mt-10 font-poppins font-medium text-[18px] text-primary hover:bg-teal-500 bg-teal-400 rounded-[10px] outline-none ${styles}`}
              >
                Get In Touch
              </button>
            </Link>
          </div>

          <div className={`${layout.sectionImg} flex-col`}>
            {features.map((feature, index) => (
              <FeatureCard key={feature.id} {...feature} index={index} />
            ))}
          </div>
        </section>
      </div>
    </div>
  </>
);

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row p-6 rounded-[20px] ${
      index !== features.length - 1 ? "mb-6" : "mb-0"
    } bg-gray-100 hover:bg-gray-200`}
  >
    <div
      className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-teal-300`}
    >
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-black text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-black text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

export default Business;
