import { useState, useEffect } from "react";
import styles from "../style";
import { menu1, close1, logo, mainlogo, email } from "../assets";
import { navLinks } from "../constants";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
const Navbar = () => {
  const [active, setActive] = useState("/");
  const [toggle, setToggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let timeout;

    const handleScroll = () => {
      setIsScrolled(true);
      setToggle(false);

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsScrolled(false);
      }, 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* first navbar for phone number and linked in */}
      <nav
        className=" bg-slate-200 flex-1  fixed top-0 left-0  z-50 w-full flex  justify-end items-center navbar"
        style={{
          opacity: isScrolled ? 0 : 1,
          transition: "opacity 0.5s",
        }}
      >
        <img src={email} alt="" className="w-[40px] h-[23px]  object-contain" />

        <h2 className=" hover:text-teal-500 pr-8  cursor-pointer">
          info@mebiocon.com{" "}
        </h2>
      </nav>
      {/* newnavbar */}

      {/* main navbar */}
      <nav
        className="pl-2 font-poppins bg-slate-100 pr-10 fixed top-6 left-0 bg-white z-50 w-full flex  justify-between items-center navbar"
        style={{
          opacity: isScrolled ? 0 : 1,
          transition: "opacity 0.5s",
        }}
      >
        <img
          src={mainlogo}
          alt="mebiocon"
          className="w-[220px] h-[72.14px] pl-4 object-contain"
        />

        <ul className="list-none sm:flex hidden justify-end items-center flex-1">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins ${
                location.pathname === nav.id ? "text-teal-500" : "text-black"
              } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
            >
              <Link
                to={nav.id}
                className={`relative font-weight-400 transition-colors link ${
                  location.pathname === nav.id ? "active" : ""
                }`}
              >
                {nav.title}
                <span className="after"></span>
              </Link>
            </li>
          ))}
        </ul>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img
            src={toggle ? close1 : menu1}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col">
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-dimWhite"
                  } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                >
                  <Link to={nav.id}>{nav.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
