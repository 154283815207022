import styles from "../style";
import { mainlogo } from "../assets";
import { footerLinks, socialMedia } from "../constants";
import { Link, useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  return (
    <>
      <div className={`bg-grey-300 ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section
            className={`${styles.flexCenter} ${styles.paddingY} flex-col`}
          >
            <div
              className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}
            >
              <div className="flex-[1] flex flex-col justify-start mr-10">
                <img
                  src={mainlogo}
                  alt="mebiocon"
                  className="w-[190px] h-[72.14px] object-contain"
                />
                <p className={`${styles.paragraph} mt-4 max-w-[312px]`}>
                  A name of excellence
                </p>
              </div>

              <div className="flex-[1.5] w-full flex flex-col justify-end flex-wrap md:mt-0 mt-10">
                <ul className="list-none ">
                  {footerLinks.map((footerlink, index) => (
                    <div
                      key={footerlink.title}
                      className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}
                    >
                      <li
                        key={footerlink.id}
                        className={`font-poppins hover:text-teal-500 font-normal cursor-pointer text-[16px] ${
                          location.pathname === footerlink.id
                            ? "text-teal-500"
                            : "text-black"
                        } ${
                          index === footerLinks.length - 1 ? "mr-0" : "mr-4"
                        } mb-4 transition-all duration-300 transform hover:translate-x-2`}
                      >
                        <Link to={footerlink.id}>{footerlink.title}</Link>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>

            <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
              <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-black">
                Copyright Ⓒ 2023 Mebiocon. All Rights Reserved.
              </p>

              {/* <div className="flex flex-row md:mt-0 mt-6">
                {socialMedia.map((social, index) => (
                  <img
                    key={social.id}
                    src={social.icon}
                    alt={social.id}
                    className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                      index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
                    }`}
                    onClick={() => window.open(social.link)}
                  />
                ))}
              </div> */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Footer;
