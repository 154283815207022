import styles from "../style";
import {
  discount,
  homepic,
  background1,
  background3,
  background2,
  homeimage1,
  homeimage2,
  homeimage3,
} from "../assets";
import { servicesData, ServiceBox } from "../constants";
import GetStarted from "./GetStarted";
import Slider from "./Slider";
const images = [
  homeimage2,
  homeimage1,
  homeimage3,
  // Add more image URLs as needed
];
const texts = [
  "lorem ajsjaalllllllllllllllllllllllslorem ajsjaallllllllllllllllllllllls",
  "Text 2lorem ajsjaalllllllllllllllllllllllslorem ajsjaallllllllllllllllllllllls",
  "Text 3",
  // Add more texts as needed
];
const Hero = () => {
  const bg = {
    backgroundImage: `url(${background1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      {/* <div className="flex flex-col ">
        <Slider images={images} texts={texts} />
      </div> */}
      {/* //for line
      <div class="mt-6 text-center">
        <h2 class="text-2xl sm:text-4xl font-bold text-teal-600">Contact Us</h2>
        <p class="text-base sm:text-lg mt-2">
          Welcome to Mebiocon, a leading provider of comprehensive regulatory
          affairs services for the medical devices industry.
        </p>
      </div> */}

      {/* <div class={`${styles.flexCenter} relative  sm:py-13 py-4 `}>
        <img
          src={background1}
          alt="Image"
          class={`w-full h-auto object-cover sm:py-9 py-2`}
        />
        <div class="absolute inset-0 flex flex-col  justify-center text-white">
          <h2
            class={`text-[40px] pl-10 font-poppins leading-[24px] pt-30 mt-20   font-bold mb-4 ml-6 text-teal-600`}
          >
            Mebiocon
          </h2>
          <p class="text-[14px] pl-10  ml-6 sm:block hidden">
            A leading provider of regulatory affairs services
          </p>
        </div>
      </div> */}
      {/* extra lines added */}
      {/* <div class="mt-2 pt-2 flex sm:mt-20 sm:pt-20 text-center">
        <h2 class="text-2xl font-poopins sm:text-4xl font-bold text-teal-600">
          Mebiocon in a nutshell
        </h2>
        <p class="text-base font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
          We are here to be your trusted regulatory partner, providing guidance,
          support, and peace of mind as you navigate the complexities of medical
          device regulations.
        </p>
      </div> */}
      <div className="flex pt-10 mt-10 flex-col mb-0 sm:mb-20 pb-0 sm:pb-20 ">
        <Slider images={images} texts={texts} />
      </div>
      <div
        className={`bg-white flex-col mt-4 sm:mt-20 pt-5 sm:pt-20  sm:py-20 py-0 ${styles.flexStart} `}
      >
        <div className="flex flex-col mt-10 pt-10  ">
          <h2 className="text-2xl text-center mt-0 sm:mt-20 font-poopins sm:text-4xl font-bold text-teal-600">
            MEBIOCON in a nutshell
          </h2>
          <p className="text-base text-center font-poopins pl-7 pr-7 ml-5 mr-5 text-[18px] sm:text-[28px]  leading-[20px] sm:leading-[40px] mt-2">
            We are here to be your trusted regulatory partner, providing
            guidance, support, and peace of mind as you navigate the
            complexities of medical device regulations.
          </p>
        </div>
        <div className={`${styles.boxWidth} mt-10`}>
          <section
            id="home"
            className={`flex md:flex-row flex-col ${styles.paddingY}`}
          >
            <div
              className={`flex-1 ${styles.flexStart} text-center pl-4 ml-4 flex-col xl:px-0 sm:px-4 px-4`}
            >
              <div className="flex  flex-col justify-between items-center w-full">
                <h1 className="font-poppins font-semibold sm:text-[58px] text-[30px] text-black ss:leading-[70.8px] leading-[30px] ">
                  Your Partner for <br className="sm:block hidden" />{" "}
                  <span className="text-teal-400  sm:text-[68px] text-[30px] ss:leading-[70.8px] leading-[30px]">
                    Technical
                  </span>{" "}
                </h1>
                <h1 className="font-poppins  font-semibold ss:text-[58px] text-[30px] text-black ss:leading-[70.8px] leading-[30px] ">
                  Documentation
                </h1>{" "}
                <p className="font-poppins pb-2 text-black font-normal  text-[15px] sm:text-[18px]  leading-[18.8px] ss:leading-[30.8px]  text-black max-w-[470px] mt-2">
                  Full Technical Documentation (STED) services and the
                  Industry’s Fastest document compilation for EU MDR. Ace your
                  audits, maximize your team’s time and budget.
                </p>
              </div>
            </div>

            <div className="md:w-1/2">
              <img
                src={homepic}
                alt="Image"
                className="md:max-w-full rounded-lg shadow-2xl"
              />
            </div>
          </section>
        </div>
      </div>
      {/* services */}

      <div className="flex flex-col ">
        <h2 className="text-2xl  text-center  font-poopins sm:text-4xl font-bold text-teal-600">
          Our Services
        </h2>
      </div>

      <div className="services-container m-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {servicesData.map((service, index) => (
          <ServiceBox
            key={index}
            title={service.title}
            content={service.content}
            image={service.image}
          />
        ))}
      </div>
    </>
  );
};

export default Hero;
