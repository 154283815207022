import React from "react";

import styles from "./style";
import {
  Home,
  Navbar,
  Hero,
  Footer,
  Business,
  Contact,
  About,
  Services,
  ScrollToTop,
} from "./components";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
const Layout = () => {
  return (
    <>
      <div className="bg-gray-100 w-full overflow-hidden">
        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <Navbar />
          </div>
        </div>
      </div>
    </>
  );
};
const App = () => (
  <>
    <Router>
      <Layout />
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Services" element={<Services />} />
      </Routes>
      <div className="bg-gray-100 w-auto overflow-hidden">
        <Footer />
      </div>
    </Router>

    {/* <div className="bg-gray-100 w-full overflow-hidden"> */}
    {/* <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}> */}
    {/* <Navbar /> */}
    {/* </div>
      </div> */}

    {/* <div className={`bg-white ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}> */}
    {/* <Hero /> */}
    {/* </div>
      </div> */}
    {/* <div className={`bg-white ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}> */}
    {/* <Business /> */}

    {/* </div>
      </div> */}

    {/* <div className={`bg-grey-300 ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}> */}
    {/* <Stats />
        <Business />
        <Billing />
        <CardDeal />
        <Testimonials />
         */}

    {/* <Footer /> */}
    {/* </div>
      </div> */}
    {/* </div> */}
  </>
);

export default App;
