import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  airbnb,
  binance,
  coinbase,
  dropbox,
  send,
  shield,
  star,
  paperplane,
  listcheck,
  lock,
  chart,
  documentation1,
  documentation2,
  manworking1,
  manworking2,
  manworking3,
  manworking4,
  bg1,
  bg10,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
  bg7,
  bg8,
  bg9,
  background3,
} from "../assets";
//for services
export const servicesData = [
  {
    title: "Quality Management System",
    content:
      "We assist in establishing, implementing, and maintaining effective Quality Management Systems (QMS) to ensure compliance with ISO 13485, FDA QSR (Quality System Regulation), and other international standards. We provide guidance on QMS documentation, training, audits, and risk management to enhance product quality and patient safety.",
    image: documentation1,
  },

  {
    title: "Technical Documentation",
    content:
      "Ensure regulatory compliance and successful product commercialization with our Technical Documentation services. ",
    image: bg1,
  },
  {
    title: "Audit and Inspection",
    content:
      "Ensure Compliance and Quality with our Audit and Inspection Services for the Medical Device Industry.",
    image: manworking3,
  },
  {
    title: "Regulatory Strategy Development",
    content:
      "Our expert consultants work closely with you to develop tailored regulatory strategies that align with your business goals. We analyze the regulatory landscape, assess product classification, and provide guidance on the most appropriate regulatory pathways to streamline market access and ensure compliance.",
    image: bg2,
  },
  {
    title: "Product Classification and Regulatory Pathway Determination",
    content:
      "We assist in accurately classifying your medical devices according to regional regulations, such as EU MDR 2017/745. We help determine the appropriate regulatory pathway for your products, including 510(k), PMA, De Novo, CE marking, and other global market clearances.",
    image: bg3,
  },
  {
    title: "Pre-Market Submissions",
    content:
      "Our team supports the preparation and submission of pre-market applications, including 510(k) submissions, IDE (Investigational Device Exemption) applications, PMAs (Pre-Market Approvals), and CE marking technical files. We ensure your submissions are comprehensive, compliant, and positioned for successful regulatory approvals.",
    image: bg4,
  },
  {
    title: "Clinical Evaluation and Evidence Generation",
    content:
      "Our team assists in designing and implementing clinical evaluation strategies, including clinical studies, literature reviews, and post-market surveillance activities. We ensure the generation of robust clinical evidence to support the safety and efficacy of your medical devices, meeting regulatory requirements and addressing the needs of stakeholders.",
    image: bg10,
  },
  {
    title: "Post-Market Surveillance and Vigilance",
    content:
      "We help establish post-market surveillance systems, including complaint handling, adverse event reporting, and trend analysis. Our experts assist in developing post-market surveillance strategies and support the preparation of post-market reports to ensure ongoing compliance, patient safety, and proactive risk management.",
    image: bg6,
  },
  {
    title: "Regulatory Compliance Training",
    content:
      "We offer customized training programs on medical device regulations, including FDA requirements, EU MDR, and international standards. Our interactive training sessions help your team gain a comprehensive understanding of regulatory compliance, enhancing their capabilities in navigating the regulatory landscape effectively.",
    image: bg7,
  },
  {
    title: "Regulatory Intelligence and Updates",
    content:
      "We keep you informed about the latest regulatory changes, industry trends, and emerging requirements. Our regulatory intelligence services provide timely updates, ensuring you stay ahead of evolving regulations and proactively adapt your strategies to maintain compliance and market competitiveness.",
    image: bg8,
  },
  {
    title: "International Market Expansion",
    content:
      "We support your expansion into new international markets by conducting regulatory assessments, assisting with localization requirements, and guiding you through the regional registration and approval processes. Our global expertise helps you navigate diverse regulatory landscapes and unlock new business opportunities.",
    image: bg9,
  },
  {
    title: "Regulatory Compliance Gap Analysis and Remediation",
    content:
      "Our team conducts comprehensive regulatory compliance gap assessments to identify areas of improvement and non-compliance. We provide detailed remediation plans, guiding you through corrective actions to ensure your products and processes meet all regulatory requirements.",
    image: bg2,
  },
  {
    title: "European Authorized Representative (EC Rep) Services",
    content:
      "For medical device manufacturers outside the European Union (EU), we offer comprehensive EC Rep services. As your authorized representative, we ensure compliance with the EU MDR and assist in fulfilling the legal obligations related to product registration, post-market surveillance, and interaction with competent authorities within the EU.",
    image: bg10,
  },
  {
    title: "Person Responsible for Regulatory Compliance (PRRC) Services",
    content:
      "Under the EU MDR, medical device manufacturers must designate a Person Responsible for Regulatory Compliance (PRRC). We provide qualified professionals who serve as your PRRC, ensuring adherence to regulatory requirements, overseeing conformity assessments, and facilitating communication with authorities, allowing you to meet the regulatory obligations effectively.",
    image: background3,
  },
];
export const ServiceBox = ({ title, content, image }) => (
  <div className="service-box p-6 shadow-lg rounded-lg bg-white hover:scale-105  hover:shadow-xl transition-all duration-300">
    <div className="relative overflow-hidden rounded-lg">
      <img
        src={image}
        alt={title}
        className="w-full h-auto transform hover:scale-110"
      />
    </div>
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{content}</p>
    </div>
  </div>
);

//navbar
export const navLinks = [
  {
    id: "/",
    title: "HOME",
  },
  {
    id: "/Services",
    title: "SERVICES",
  },
  {
    id: "/About",
    title: "ABOUT US",
  },
  {
    id: "/contact",
    title: "CONTACT US",
  },
];

export const footerLinks = [
  {
    id: "/Services",
    title: "Our Services",
  },
  {
    id: "/About",
    title: "About Us",
  },
  {
    id: "/contact",
    title: "Contact Us",
  },
  // // {
  // //   title: "Useful Links",
  // //   links: [
  // //     {
  // //       name: "Content",
  // //       link: "https://www.hoobank.com/content/",
  // //     },
  // //     {
  // //       name: "How it Works",
  // //       link: "https://www.hoobank.com/how-it-works/",
  // //     },
  // //     {
  // //       name: "Create",
  // //       link: "https://www.hoobank.com/create/",
  // //     },
  // //     {
  // //       name: "Explore",
  // //       link: "https://www.hoobank.com/explore/",
  // //     },
  // //     {
  // //       name: "Terms & Services",
  // //       link: "https://www.hoobank.com/terms-and-services/",
  // //     },
  // //   ],
  // },
  // {
  // title: "",
  // links: [
  //   {
  //     name: "Our services",
  //     link: "https://www.mebiocon.com//",
  //   },
  //   {
  //     name: "About",
  //     link: "https://www.mebiocon.com//",
  //   },
  //   {
  //     name: "Contact",
  //     link: "https://www.mebiocon.com//",
  //   },
  // {
  //   name: "Blog",
  //   link: "https://www.hoobank.com/blog/",
  // },
  // {
  //   name: "Newsletters",
  //   link: "https://www.hoobank.com/newsletters/",
  // },
  // ],
  // },
];
export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/mebiocon/?igshid=MzNlNGNkZWQ4Mg%3D%3D",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/mebiocon",
  },

  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];
export const features = [
  {
    id: "feature-1",
    icon: chart,
    title: "Client Care",
    content: "Your satisfaction is our utmost priority every step of the way.",
  },
  {
    id: "feature-2",
    icon: paperplane,
    title: "Continuous Improvement",
    content:
      "Committed to ongoing enhancements and innovation to drive your success.",
  },
  {
    id: "feature-3",
    icon: lock,
    title: "Regulatory Compliance",
    content:
      "Ensuring your medical devices meet all necessary regulations and standards.",
  },
  {
    id: "feature-1",
    icon: listcheck,
    title: "Audit Readiness Assistance",
    content:
      "Preparing your organization for regulatory audits with meticulous attention to detail.",
  },
];

// export const feedback = [
//   {
//     id: "feedback-1",
//     content:
//       "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
//     name: "Herman Jensen",
//     title: "Founder & Leader",
//     img: people01,
//   },
//   {
//     id: "feedback-2",
//     content:
//       "Money makes your life easier. If you're lucky to have it, you're lucky.",
//     name: "Steve Mark",
//     title: "Founder & Leader",
//     img: people02,
//   },
//   {
//     id: "feedback-3",
//     content:
//       "It is usually people in the money business, finance, and international trade that are really rich.",
//     name: "Kenn Gallagher",
//     title: "Founder & Leader",
//     img: people03,
//   },
// ];

// export const stats = [
//   {
//     id: "stats-1",
//     title: "User Active",
//     value: "3800+",
//   },
//   {
//     id: "stats-2",
//     title: "Trusted by Company",
//     value: "230+",
//   },
//   {
//     id: "stats-3",
//     title: "Transaction",
//     value: "$230M+",
//   },
// ];

// export const clients = [
//   {
//     id: "client-1",
//     logo: airbnb,
//   },
//   {
//     id: "client-2",
//     logo: binance,
//   },
//   {
//     id: "client-3",
//     logo: coinbase,
//   },
//   {
//     id: "client-4",
//     logo: dropbox,
//   },
// ];
